/* MISC */
.inputErrorText {
  font-size: 16px;

  margin-top: 2px;
  margin-left: 4px;
}

.white .inputErrorText {
  color: #ddd;
}

.black .inputErrorText {
  color: #222;
}

/* INPUT */
.textarea textarea {
  display: block;

  background: transparent;
  border-radius: 6px;
  outline: none;

  font-size: 20px;

  min-height: 54px;
  height: 54px;
  width: 100%;

  padding: 12px 20px;
}

.textarea.white textarea {
  color: #fff;
  border: 1px solid #bbb;
}

.textarea.white:focus {
  border-color: #fff;
}

.textarea.white .textareaErrorText {
  color: #ddd;
}

.textarea.black textarea {
  color: #000;
  border: 1px solid #222;
}

.textarea.black:focus {
  border-color: #000;
}

.textarea.black .textareaErrorText {
  color: #222;
}

.textarea.error textarea {
  border-color: rgb(255, 80, 60);
}

.textareaErrorText {
  font-size: 16px;

  margin-top: 2px;
  margin-left: 4px;
}

/* INPUT */
.input input {
  display: block;

  background: transparent;
  border-radius: 6px;
  outline: none;

  font-size: 20px;

  height: 54px;
  width: 100%;

  padding: 0 20px;
}

.input.white input {
  color: #fff;
  border: 1px solid #bbb;
}

.input.white:focus {
  border-color: #fff;
}

.input.black input {
  color: #000;
  border: 1px solid #222;
}

.input.black:focus {
  border-color: #000;
}

.input.error input {
  border-color: rgb(255, 80, 60);
}

/* BUTTON */
.button {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.25em;

  cursor: pointer;

  padding: 16px 32px;

  width: fit-content;
  height: fit-content;

  border: none;
  outline: none;
}

.button.black {
  background: #000;
  color: #fff;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.button.black:hover {
  background: #333;
}

.button.white {
  background: #fff;
  color: #000;

  box-shadow: rgba(255, 255, 255, 0.25) 0px 13px 27px -5px,
    rgba(255, 255, 255, 0.3) 0px 8px 16px -8px;
}

.button.white:hover {
  background: #ddd;
}

/* DROPDOWN */
.dropdown {
  background: transparent;
  outline: none;
}

.dropdownInner {
  position: relative;
}

.dropdown .dropdownText {
  font-size: 20px;

  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 54px;
  width: 100%;

  padding: 0 20px;

  cursor: pointer;

  border-width: 1px;
  border-style: solid;
}

.dropdown.error .dropdownText {
  border-color: rgb(255, 80, 60) !important;
}

.dropdown.black .dropdownText {
  color: #000;
  border-color: #222;
}

.dropdown.white .dropdownText {
  color: #fff;
  border-color: #bbb;
}

.dropdownItems {
  position: absolute;

  top: calc(100% + 6px);
  left: 0;

  padding: 16px 0;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  background: #2f2f2f;
  border-radius: 4px;
}

.dropdownItem {
  font-size: 16px;
  color: #fff;

  padding: 6px 16px;

  cursor: pointer;
}

.dropdownItem:hover {
  background: rgba(255, 255, 255, 0.075);
}
