.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  height: fit-content;
  width: 100vw;

  position: relative;
  z-index: 2;

  overflow-y: scroll;
  overflow-x: hidden;
}
