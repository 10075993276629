.container {
  justify-content: flex-start !important;

  margin-top: 94px;

  min-height: calc(100vh - 94px) !important;
}

.hero {
  position: relative;

  margin: 120px auto 100px auto;
  padding: 0 16px 0 16px;

  width: 100%;
  max-width: 1400px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.info {
  display: flex;
  flex-direction: column;

  width: fit-content;
}

.button {
  margin-top: 24px;
}

.heroDescription {
  max-width: 750px;

  color: #000;
  font-size: 24px;
  line-height: 1.5;

  margin-top: 32px;
}

.heroText {
  color: #242425;
  font-size: 64px;
  letter-spacing: 1px;

  width: fit-content;
}

.faq {
  background: #000;
  width: 100vw;

  padding: 75px 24px 125px 24px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.faqHeader {
  font-size: 64px;
}

.faqList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 75px;

  margin-top: 64px;
}

.faqItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 10px;

  width: fit-content;
  max-width: 1000px;
}

.faqItemHeader {
  width: 440px;

  font-size: 30px;
}

.faqItemDescription {
  flex: 1;

  line-height: 26px;
  font-size: 20px;
}

.customers {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  column-gap: 100px;

  margin-top: 100px;

  width: 100vw;
}

.customerImage {
  height: auto;
  width: 200px;
}

.contact {
  width: 100vw;

  padding: 100px 24px;
  margin-top: 100px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  column-gap: 100px;
}

.contactTexts {
  width: 425px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  width: 400px;
}

.contactInput {
  width: 100%;
}

.contactHeader {
  font-size: 40px;
}

.contactDescription {
  font-size: 24px;
}

.newsHeader {
  font-size: 64px;
  color: #000;
}

.latestNews {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  justify-content: center;
  align-items: center;

  margin-top: 64px;
}

.newsItem {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media not (prefers-reduced-motion) {
  .newsItem,
  .newsItem img,
  .newsItemHeader,
  .newsItemDate {
    transition: 250ms;
  }
}

.newsItemHeader {
  color: #000;
  font-size: 30px;
}

.newsItemDate {
  color: #000;
  font-size: 20px;
}

.newsItem:not(.one) {
  width: 350px;
}

.newsItem.one {
  width: 400px;
}

.newsSeeMoreButton {
  margin-top: 64px;
}

.newsItem.one img {
  width: 400px;
  height: 400px;
}

.newsItem:hover img {
  transform: scale(1.05);
}

.newsItem:hover .newsItemHeader {
  margin-top: 10px;
}

.newsItem:hover {
  cursor: pointer;
}

.news {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  width: 100vw;

  margin-top: 100px;

  padding: 0 24px;
}

.siteSpeed {
  width: 100vw;
  height: fit-content;

  padding: 100px 20px;
  margin-top: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #000;
}

.siteSpeed .inner {
  max-width: 1000px;
  width: 100%;
}

.siteSpeed .form {
  margin-top: 64px;
}

.siteSpeed .texts .header {
  font-size: 40px;
  color: #fff;
}

.siteSpeed .texts .description {
  font-size: 24px;
  line-height: 30px;
  color: #fff;

  margin-top: 16px;
}

.siteSpeed .form .header {
  font-size: 20px;

  width: 100%;
}

.siteSpeed .form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  column-gap: 24px;
  row-gap: 10px;
}

.siteSpeed .form .siteInput {
  flex: 1;
}

.contact.black {
  background: #000;
}

.contact.white .contactHeader,
.contact.white .contactDescription {
  color: #000;
}

@media screen and (min-width: 900px) and (max-width: 1500px) {
  .hero {
    padding: 0 64px 0 64px;
  }
}

@media screen and (max-width: 1248px) {
  .newsItem {
    width: calc(100% / 3 - (100px / 3)) !important;
  }

  .newsItem img {
    width: 100% !important;
    aspect-ratio: 1/1 !important;
    height: auto !important;
  }
}

@media screen and (max-width: 900px) {
  .faqItemHeader {
    width: 300px;
  }

  .contact {
    column-gap: 50px;
  }
}

@media screen and (max-width: 775px) {
  .faqHeader {
    text-align: center;
  }

  .news {
    padding: 48px;
  }

  .latestNews {
    flex-direction: column;
    row-gap: 50px;
  }

  .newsItem {
    width: 100% !important;
    max-width: 400px;
  }
}

@media screen and (max-width: 400px) {
  .newsHeader {
    font-size: 52px;
  }

  .newsItemHeader {
    font-size: 22px;
  }

  .newsItemDate {
    font-size: 16px;
  }
}

@media screen and (max-width: 650px) {
  .contact {
    flex-direction: column;
    row-gap: 50px;
    align-items: center;
  }

  .siteSpeed .form .header {
    text-align: center;
  }

  .siteSpeed .form {
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
  }

  .siteSpeed .form .siteInput {
    width: 100%;
  }

  .contactTexts,
  .contactForm {
    width: 100%;
  }

  .contactDescription {
    margin-top: 16px;
  }

  .contactForm {
    align-items: center;
  }

  .heroText {
    font-size: 48px;
    text-align: center;
  }

  .faqItem {
    flex-direction: column;
  }

  .faqItemHeader {
    width: fit-content;

    word-break: break-all;
  }

  .customers {
    flex-direction: column;
    row-gap: 75px;
  }

  .heroDescription {
    font-size: 22px;
    text-align: center;
  }

  .faqItemDescription {
    margin-top: 10px;

    width: fit-content;
  }

  .info {
    align-items: center;
  }

  .hero {
    padding: 0px 16px 0 16px;
    margin: 70px auto 50px auto;
  }

  .faqHeader {
    font-size: 40px;
  }
}

@media screen and (max-width: 450px) {
  .siteSpeed .texts .header {
    font-size: 32px;
  }

  .siteSpeed .texts .description {
    font-size: 20px;
  }
}

@media screen and (max-width: 400px) {
  .heroText {
    font-size: 32px;
  }

  .hero {
    padding: 0 16px 0 16px;
    margin: 45px auto 30px auto;
  }
}

@media screen and (max-width: 1200px) {
  .heroImage {
    display: none;
  }
}
